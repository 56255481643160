<template>
  <div>
    <h4 class="font-weight-bold">ประวัติการชำระเงิน</h4>

    <div v-if="allPayment.length > 0">
      <div
        v-for="payment in allPayment"
        :key="payment.payment_id"
        class="history"
      >
        <div class="row">
          <p class="col-5">{{ payment.course }}</p>
          <p class="col-3 text-right">
            {{ numberComma(payment.price.toFixed(2)) }} บาท
          </p>
          <p class="col-4 text-center">{{ payment.date | moment }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <h3 class="color-text text-center">ไม่มีประวัติการซื้อ</h3>
    </div>
  </div>
</template>

<script>
import axios from "../../api/faAPI";
import baseUrl from "../../util/backend";
import moment from "moment";
import numberComma from "../../util/numberComma";
export default {
  data() {
    return {
      allPayment: [],
    };
  },
  methods: {
    numberComma,
    async getPayment() {
      try {
        const res = await axios.get(`${baseUrl}/getUserTransaction`, {
          params: { user_id: this.$store.getters.getUser.user_id },
        });
        console.log(res.data.transaction);
        this.allPayment = res.data.transaction;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
  },
  filters: {
    moment(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  created() {
    this.getPayment();
  },
};
</script>

<style scoped>
.history {
  padding: 15px;
}

.history p {
  margin-bottom: 0;
}

.history:nth-child(even) {
  background-color: #f9f9f9;
}
</style>
